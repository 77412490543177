<template>
  <div>
    <div data-app class="container" style="width: 100%" v-if="!isVerified">
      <v-form
        v-model="valid"
        ref="form1"
        lazy-validation
        id="accountType"
        class="mb-15"
      >
        <div class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
          <span>Create Password</span>
          <div class="form-group mb-form">
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model.trim="$v.password.$model"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  label="Create Password"
                  required
                  @click:append="show1 = !show1"
                ></v-text-field>
                <div class="invalid-feedback" v-if="passwordError">
                  <span class="invalid-feedback" v-if="!$v.password.required"
                    >Password is required</span
                  >
                  <span
                    class="invalid-feedback"
                    v-else-if="!$v.password.minLength"
                    >minimum length should be 8 characters</span
                  >
                </div>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="confirmPassword"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  name="input-10-1"
                  label="Confirm Password"
                  :rules="[(v) => !!v || 'Item is required']"
                  required
                  hint="At least 8 characters"
                  @click:append="show2 = !show2"
                ></v-text-field>
                <div class="invalid-feedback" v-if="confirmPasswordError">
                  <span
                    class="invalid-feedback"
                    v-if="!$v.confirmPassword.sameAsPassword"
                    >Password is not same</span
                  >
                </div>
              </v-col>
            </v-row>
          </div>
        </div>

        <br />
        <div class="text-center">
          <v-btn
            rounded
            class="btn btn-success mr-3 mb-9 mt-5"
            style="width: 100%; color: #ffff; height: 60px"
            @click="Submit"
          >
            Create Password
            <span v-if="isCreating">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </span>
          </v-btn>
        </div>
      </v-form>
    </div>
    <div v-else class="text-center">
      <h1>Account Verified</h1>
      <h5 style="margin-bottom: 5vh">
        Please Login By Clicking
        <router-link to="/login">
          <span class="menu-text">Here</span>
        </router-link>
      </h5>
    </div>
  </div>
</template> 

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
export default {
  mixins: [validationMixin],
  validations: {
    password: {
      required,
      minLength: minLength(8),
    },
    confirmPassword: {
      required,
      minLength: minLength(8),
      sameAsPassword: sameAs("password"),
    },
  },
  data() {
    return {
      show1: false,
      show2: false,
      password: "",
      confirmPassword: "",
      passwordError: false,
      confirmPasswordError: false,
      isVerified: false,
      id: this.$route.params.id,
      user: null,
      isCreating: false,
    };
  },
  mounted() {
    this.FetchData();
  },
  computed: {
    ...mapGetters(["GetUser"]),
  },
  methods: {
    async FetchData() {
      await this.$store.dispatch("getUser", this.id).then((res) => {
        this.isVerified = this.GetUser.isVerified;
        this.user = this.GetUser;
        });
    },
    async Submit() {
      this.isCreating = true;
      this.$v.$touch();
       this.passwordError = this.$v.password.$invalid;
      this.confirmPasswordError = this.$v.confirmPassword.$invalid;
      if (!this.$v.$anyError) {
        this.user.password = this.password;

        await this.$store.dispatch("savePassword", this.user).then((res) => {
           this.isVerified = res.data.isVerified;
          this.user = this.res.data;
          this.isCreating = false;
        });
      } else {
        this.isCreating = false;
      }
    },
  },
};
</script>

<style>
.error--text {
  color: rgba(252, 9, 9, 0.7) !important;
}
.invalid-feedback {
  display: initial;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}
</style>